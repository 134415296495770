<template>
  <div class="auth d-flex align-center justify-center" style="height: 100vh;">
    <div id="firebaseui-auth-container" style="width: 480px;" />
  </div>
</template>
<script>
import { ui, uiConfig } from '@/fb'

export default {
  name: 'Auth',
  mounted() {
    this.$nextTick(this.init)
  },
  methods: {
    init() {
      ui.start('#firebaseui-auth-container', uiConfig)
    }
  }
}
</script>